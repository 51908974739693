import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { MdMail, MdLocationOn } from "react-icons/md";
import {
  FaLinkedin,
  FaYoutube,
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaEnvelope,
  FaLocationDot,
} from "react-icons/fa";
import axios from "axios";

import { API_URL } from "../Api";
const Api = `${API_URL}/ContactPage`;

function Team() {
  const history = useHistory();

  const [contactForm, setContactForm] = useState({
    Name: "",
    Company: "",
    Email: "",
    ContactNumber: "",
    Subject: "",
    Message: "",
  });
  const [Successtitle, setSuccesstitle] = useState(
    "Your message has been successfully sent. We will contact you very soon!"
  );

  // onchanges
  const adding = ({ target: { name, value } }) => {
    setContactForm({ ...contactForm, [name]: value });
  };

  // submit
  const Formsubmit = (event) => {
    event.preventDefault();

    let data = {
      Name: contactForm.Name,
      Company: contactForm.Company,
      Email: contactForm.Email,
      ContactNumber: contactForm.ContactNumber,
      Subject: contactForm.Subject,
      Message: contactForm.Message,
    };

    axios.post(`${Api}`, data).then((res) => {
      // this.props.history.push("/Training")
      setContactForm({
        Name: "",
        Company: "",
        Email: "",
        ContactNumber: "",
        Subject: "",
        Message: "",
      });
      history.push({ pathname: "/enquire/success", state: Successtitle });
    });
  };

  return (
    <section id="contactus" className="p-0 item-active bg-000">
      <div className="container-fluid py-md-4 ">
        <div className="row d-mb-grid m-0">
          <div className="col-md-12 pl-2">
            <div className="row m-0">
              <div className="col-sm-2 col-md-2 mob_order_2 pl-0 mb-md-4">
                <div className="contact-address contact-wraper">
                  <Link className="white contact_logo mb-md-5" to="/">
                    <img
                      src="https://www.hrtech.sg/assets/img/TLD_Logo_Transparent_White.png"
                      alt="LOGO"
                      className="logo_img"
                      style={{ height: "50px" }}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-sm-2 col-md-2 d-md-block d-none mb-md-4 mt-4">
                <div className=" d-flex align-items-center">
                  <div className="add-icon">
                    <MdMail className="text-left add-icon" />
                  </div>
                  <p className="mail">
                    <a
                      href="mailto:maya@hrtech.sg"
                      className="ml-0 txt_lightwhite"
                    >
                      maya@hrtech.sg
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-sm-1 col-md-1 d-md-block d-none mb-md-4 mt-2"></div>
              <div className="col-sm-1 col-md-1 d-md-block d-none mb-md-4 mt-2">
                <div className="  d-none d-md-block pl-0">
                  <div className="sin-add py-4 ">
                    {/* <a  href="https://www.instagram.com/hrtech_official/" target="_blank" rel="noopener noreferrer"><FaInstagram className='fa-sm text-white lin_icon'/></a>  */}
                    {/* <a href='https://www.linkedin.com/company/hrtech-singapore/' target="_blank" rel="noopener noreferrer"><FaFacebookF className='fa-sm text-white lin_icon'/></a>  */}
                    {/* <a href='https://twitter.com/hrtechtweets/' target="_blank" rel="noopener noreferrer"><FaTwitter className='fa-sm text-white lin_icon'/></a>  */}
                    <a
                      href="https://www.linkedin.com/company/hrtech-singapore/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin className="fa-sm text-white lin_icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 d-md-block d-none mb-md-4 footer-address-mt">
                <p className="footer_location">
                  <MdLocationOn className="text-left add-icon" />
                </p>
                <div className="d-inline-block footer_locate">
                  <p className="txt_lightwhite ">IKIGAI ENABLERS PTE. LTD.</p>
                  <p className="txt_lightwhite ">#05-95,</p>
                  <p className="txt_lightwhite ">18 Boon Lay Way,</p>
                  <p className="txt_lightwhite ">Singapore - 609966.</p>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 d-md-block d-none mb-md-4 footer-address-mt">
              <p className="footer_location">
                  <MdLocationOn className="text-left add-icon" />
                </p>
                <div className="d-inline-block footer_locate">
                  <p className="txt_lightwhite ">DSO-THUB-G-D-FLEX-G116B,</p>
                  <p className="txt_lightwhite ">THUB,</p>
                  <p className="txt_lightwhite ">Dubai Silicon Oasis (DSO),</p>
                  <p className="txt_lightwhite ">Dubai .</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid   pt-0   "
        style={{ borderBottom: "white solid 1px" }}
      >
        <div className="row d-mb-grid m-0 footer_links">
          <div className="col-md-12 pl-2">
            <div className="row m-0">
              <div className="col-sm-3 col-md-3 mob_order_2 pl-0 mb-md-4">
                <div className="contact-address contact-wraper">
                  <p className="txt_lightwhite ">
                    We are a Singapore-headquartered HRTech Solutions firm,
                    dedicated to facilitating Workplace and Workforce
                    Transformations that drive robust Business and HR outcomes
                    for enterprises across Asia and the Middle East.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 d-md-block d-none mb-md-4">
                <div className="contact-wraper w-100 text-white">
                  <p className="f-18 quick_links_head bb_lightgrey">Services</p>
                </div>
                <div className="quik_link_contents txt_lightwhite f-16 quick-link-sty">
                  {/* {<a
                    href="/certifications/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>Academy</p>
                  </a>} */}
                  <a
                    href="https://www.hrtech.sg/resources/reports/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>Advisory & Consulting </p>
                  </a>

                  <Link
                    to="/marketplace/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>Marketplace </p>
                  </Link>
                  <a
                    href="https://www.hrtech.sg/jobs/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>Talent On-Demand</p>
                  </a>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 d-md-block d-none mb-md-4">
                <div className="contact-wraper w-100 text-white">
                  <p className="f-18 quick_links_head bb_lightgrey">
                    Resources
                  </p>
                </div>
                <div className="quik_link_contents txt_lightwhite f-16 quick-link-sty">
                  <a
                    href="https://www.hrtech.sg/resources/reports/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>Reports</p>
                  </a>
                  <a
                    href="https://www.hrtech.sg/resources/ebooks/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>eBooks</p>
                  </a>

                  <a
                    href="https://www.hrtech.sg/resources/infographics/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>Infographics </p>
                  </a>
                  <a
                    href="https://www.hrtech.sg/resources/case_studies/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>Case Studies</p>
                  </a>
                  <a
                    href="https://www.hrtech.sg/blog/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>Blogs</p>
                  </a>
                  <a
                    href="https://www.hrtech.sg/tech_talks/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>TechTalks</p>
                  </a>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 d-md-block d-none mb-md-4">
                <div className="contact-wraper w-100 text-white">
                  <p className="f-18 quick_links_head bb_lightgrey">Company</p>
                </div>
                <div className="quik_link_contents txt_lightwhite f-16 quick-link-sty">
                  {/* {<a
                    href="https://www.hrtech.sg/about-us/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>About Us</p>
                  </a>} */}
                  <a
                    href="https://www.hrtech.sg/careers/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>Careers </p>
                  </a>

                  <a
                    href="https://www.hrtech.sg/contact-us/"
                    className="txt_lightwhite dropdown-item pl-0"
                  >
                    <p>Contact Us </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
